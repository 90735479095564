<template>
    <rb-header
        heading="Code Redemption Admin"
        :img-src="image"
        @headerActionClicked="navigateToOverview"
    >
        <LogoutButton />
    </rb-header>
</template>
<script lang="ts">
import LogoutButton from './LogoutButton.vue'
import { defineComponent } from 'vue'
import image from '@/assets/logo_neg.png'

export default defineComponent({
    data() {
        return {
            image: image,
        }
    },
    components: {
        LogoutButton,
    },
    methods: {
        navigateToOverview() {
            if (!this.$route.fullPath.startsWith('/overview')) {
                this.$router.push('/overview')
            }
        },
    },
})
</script>
