<template>
    <rb-card heading="404 Not Found">
        <div slot="info">
            <p>
                The requested Page does not exist, please proceed to
                <router-link :to="'/'">Home</router-link>
            </p>
        </div>
    </rb-card>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({})
</script>
