<template>
    <div id="app">
        <TopBar />
        <ErrorBar v-if="isInError" />
        <InfoBar v-if="hasInfo" />
        <router-view class="content-box" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TopBar from './components/TopBar.vue'
import ErrorBar from './components/ErrorBar.vue'
import InfoBar from './components/InfoBar.vue'

export default defineComponent({
    components: {
        TopBar,
        ErrorBar,
        InfoBar,
    },
    computed: {
        isInError(): boolean {
            return this.$store.getters.isInError
        },
        hasInfo(): boolean {
            return this.$store.getters.hasInfo
        },
    },
})
</script>

<style lang="scss">
@import 'node_modules/@rbmh-design-system/base-styles/scss/styles';
body {
    margin: 0;
}
#app {
    font-family: $font-family-bull;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color--black;
    background-color: #efefef;
    height: 100vh;
}
.content-box {
    margin-top: 1em;
    margin-left: 20%;
    margin-right: 20%;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(239, 239, 239);
    border-image: initial;
    border-radius: 4px;
}
.top-container {
    display: flex;
    justify-content: space-between;
}
.margin-right {
    margin-right: 8px;
}
.refresh-button {
    margin-top: 18px;
    margin-right: 28px;
}
.card-box {
    background-color: white;
    padding: 10px;
    margin-top: 15px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(239, 239, 239);
    border-image: initial;
    border-radius: 4px;
}
.text-input {
    width: 45%;
    justify-content: flex-start;
}
.header-tabs {
    height: 100vh;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
}
.list {
    width: 70%;
    margin-left: 15%;
}
.input-error {
    color: #f69703;
    margin-top: 0;
}
</style>
