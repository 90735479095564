<template>
    <div class="header-tabs">
        <rb-tab-set>
            <div v-if="!isLoading" class="list">
                <rb-tab heading="Details" tab-id="id1">
                    <span v-if="isLoading">loading..</span>
                    <span v-else>
                        <PromotionDetailsOverviewComponent
                            :campaign-key="getCampaignKey"
                            :leader-board-participants="numberOfParticipants"
                            :has-leaderboard="hasLeaderboardToShow"
                            :stats="getPromotionStats"
                        />
                    </span>
                </rb-tab>
                <rb-tab
                    heading="Rewards"
                    tab-id="id2"
                    v-if="hasLeaderboardToShow"
                >
                    <LeaderboardRewardsComponent
                        :campaignKey="getCampaignKey"
                        :promotionKey="getPromotionKey"
                    />
                </rb-tab>
                <rb-tab
                    heading="Braze Campaign"
                    tab-id="id3"
                    v-if="hasLeaderboardToShow"
                >
                    <LeaderboardBrazeComponent
                        :campaignKey="getCampaignKey"
                        :promotionKey="getPromotionKey"
                    />
                </rb-tab>
                <rb-tab
                    heading="Rankings"
                    tab-id="id4"
                    v-if="hasLeaderboardToShow"
                >
                    <LeaderboardRankingsComponent
                        :campaignKey="getCampaignKey"
                        :promotionKey="getPromotionKey"
                    />
                </rb-tab>
                <rb-tab heading="Redemptions" tab-id="id5">
                    <RedemptionsExportComponent
                        :campaignKey="getCampaignKey"
                        :promotionKey="getPromotionKey"
                    />
                </rb-tab>
            </div>
        </rb-tab-set>
    </div>
</template>

<script lang="ts">
import LeaderboardRewardsComponent from '@/components/leaderboard/LeaderboardRewardsComponent.vue'
import LeaderboardBrazeComponent from '@/components/leaderboard/LeaderboardBrazeComponent.vue'
import PromotionDetailsOverviewComponent from '@/components/promotion/PromotionDetailsOverviewComponent.vue'
import LeaderboardRankingsComponent from '@/components/leaderboard/LeaderboardRankingsComponent.vue'
import RedemptionsExportComponent from '@/components/redemptions/RedemptionsExportComponent.vue'
import { defineComponent } from 'vue'
import { getFirstParamValue } from '@/commons/route.utils'

export default defineComponent({
    name: 'ManagementPromotionDetails',
    components: {
        LeaderboardRewardsComponent,
        LeaderboardBrazeComponent,
        PromotionDetailsOverviewComponent,
        LeaderboardRankingsComponent,
        RedemptionsExportComponent,
    },
    data: function () {
        return {
            campaignKey: '',
            promotionKey: '',
            leaderboardActive: false,
        }
    },
    mounted() {
        this.$data.campaignKey = getFirstParamValue(
            this.$route.params.campaignKey
        )
        this.$data.promotionKey = getFirstParamValue(
            this.$route.params.promotionKey
        )

        this.$store.dispatch('retrieveStatsForCampaign', this.$data.campaignKey)
        this.$store.dispatch('fetchLeaderboardStatistics', {
            campaignKey: this.$data.campaignKey,
            promotionKey: this.$data.promotionKey,
        })
    },
    methods: {
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
    },
    computed: {
        numberOfParticipants() {
            return this.$store.getters.getParticipantCount
        },
        isLoading() {
            return this.$store.getters.isLeaderboardLoading
        },
        hasLeaderboardToShow() {
            return this.$store.getters.existsLeaderboard(
                this.$data.campaignKey,
                this.$data.promotionKey
            )
        },
        getCampaignKey() {
            return this.$data.campaignKey
        },
        getPromotionKey() {
            return this.$data.promotionKey
        },
        getPromotionStats() {
            const ps = this.$store.getters.getStatisticsForPromotion(
                this.$data.campaignKey,
                this.$data.promotionKey
            )
            if (ps != null) {
                return ps
            }
            return {}
        },
    },
})
</script>

<style scoped></style>
