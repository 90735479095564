<template>
    <div class="content-box card-box">
        <rb-heading weight="bold" size="large"
            >Create a new Promotion
        </rb-heading>
        <br /><br />
        <div class="text-input">
            <rb-text weight="bold">Name:</rb-text>
            <rb-input
                v-on:inputChanged="nameChange"
                placeholder="Enter name here"
                required="true"
            ></rb-input>
        </div>
        <br />
        <div class="text-input">
            <rb-text weight="bold">PromotionKey:</rb-text>
            <rb-input
                v-on:inputChanged="keyChange"
                placeholder="Enter PromotionKey here"
                required="true"
            ></rb-input>
        </div>
        <br />
        <div class="text-input">
            <rb-text weight="bold">Description:</rb-text>
            <rb-input
                v-on:inputChanged="descriptionChange"
                placeholder="Enter description here"
                required="true"
            ></rb-input>
        </div>
        <br />
        <div class="text-input">
            <rb-text weight="bold">Max. redemptions per user:</rb-text>
            <rb-input
                v-on:inputChanged="maxRedemptionsChange"
                :value.prop="maxRedemptionsPerUser"
                required="true"
            ></rb-input>
        </div>
        <br /><br />
        <div class="text-input">
            <rb-text weight="bold">Scope:</rb-text>
            <rb-datepicker
                type="datetimerange"
                :value="scope"
                :label="'Given value: ' + scope"
                @selected="onScopeSelected"
                ref="datepicker"
                required="true"
            />
        </div>
        <br />
        <div v-if="isError">
            <p class="input-error">{{ errorMsg }}</p>
        </div>
        <div>
            <rb-button
                @click="goBack()"
                class="margin-right"
                type="primary"
                size="small"
                >Cancel
            </rb-button>
            <rb-button
                @click="savePromotion()"
                class="margin-right"
                type="primary"
                size="small"
                >Save
            </rb-button>
        </div>
    </div>
</template>

<script lang="ts">
import { createPromotion } from '@/models/createPromotion.model'
import { CampaignStatistics } from '@/models/campaignStatistics.model'
import { defineComponent } from 'vue'
import { getFirstParamValue } from '@/commons/route.utils'

export default defineComponent({
    name: 'ManagementPromotionCreator',
    data() {
        return {
            overviewLink: '/',
            promotionKey: '',
            name: '',
            description: '',
            campaignKey: '',
            maxRedemptionsPerUser: 1,
            validFrom: '',
            validUntil: '',
            scope: '',
            isError: false,
            errorMsg: '',
        }
    },
    mounted() {
        this.campaignKey = getFirstParamValue(this.$route.params.campaignKey)
    },
    methods: {
        savePromotion() {
            this.isError = false
            if (!this.name || 0 === this.name.length) {
                this.errorMsg = 'Name is required'
                this.isError = true
                return
            }
            if (!this.promotionKey || 0 === this.promotionKey.length) {
                this.errorMsg = 'PromotionKey is required'
                this.isError = true
                return
            }
            const alreadyExisting = this.promotionStats.filter(
                (value) => this.promotionKey === value.promotionKey
            )
            if (alreadyExisting.length != 0) {
                this.errorMsg =
                    'PromotionKey "' + this.promotionKey + '" already exists'
                this.isError = true
                return
            }
            if (!this.description || 0 === this.description.length) {
                this.errorMsg = 'Description is required'
                this.isError = true
                return
            }
            if (!this.scope || 0 === this.scope.length) {
                this.errorMsg = 'Scope is required'
                this.isError = true
                return
            }
            this.$store
                .dispatch(
                    'createPromotion',
                    new createPromotion(
                        this.promotionKey,
                        this.name,
                        this.description,
                        this.campaignKey,
                        this.maxRedemptionsPerUser,
                        this.validFrom,
                        this.validUntil
                    )
                )
                .then(() => {
                    this.$store.commit('setInfo', 'Created Promotion')
                    this.goBack()
                    this.$store.dispatch(
                        'fetchStatsForCampaign',
                        this.campaignKey
                    )
                })
        },
        nameChange: function (event: CustomEvent<string>) {
            this.name = event.detail
        },
        keyChange: function (event: CustomEvent<string>) {
            this.promotionKey = event.detail
        },
        descriptionChange: function (event: CustomEvent<string>) {
            this.description = event.detail
        },
        maxRedemptionsChange: function (event: CustomEvent<number>) {
            this.maxRedemptionsPerUser = event.detail
        },
        onScopeSelected: function (event: CustomEvent<string>) {
            this.scope = event.detail
            const tmp = this.scope.split('/', 2)
            this.validFrom = tmp[0]
            this.validUntil = tmp[1]
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
    },
    computed: {
        campaignStatistics() {
            return this.$store.getters.getStatisticsForCampaign(
                this.$data.campaignKey
            )
        },
        promotionStats() {
            const campaign = this.campaignStatistics as CampaignStatistics
            return campaign.promotionStats
        },
    },
})
</script>

<style scoped>
tr:nth-child(odd) {
    background: #ccc;
}

tr:nth-child(even) {
    background: #fff;
}
</style>
