<template>
    <div>
        <div class="top-container" style="justify-content: flex-end">
            <rb-button
                @click="refreshCampaigns()"
                class="margin-right refresh-button"
                type="secondary"
                size="small"
                >Refresh</rb-button
            >
        </div>
        <p v-if="isLoading" class="loading-indicator">Loading...</p>
        <ul class="campaign-list" v-else>
            <CampaignItem
                v-for="campaign in campaignList"
                v-bind:key="campaign.campaignKey"
                :campaign="campaign"
            />
        </ul>
    </div>
</template>

<script lang="ts">
import CampaignItem from './CampaignItem.vue'
import { Campaign } from '@/models/campaign.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CampaignList',
    components: {
        CampaignItem,
    },
    mounted() {
        this.$store.dispatch('retrieveCampaigns')
    },
    methods: {
        refreshCampaigns() {
            this.$store.dispatch('fetchCampaigns')
        },
    },
    computed: {
        isLoading(): boolean {
            return this.$store.state.data.campaigns.isLoading
        },
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list ?? []
        },
    },
})
</script>

<style scoped>
ul {
    padding-inline-start: 0;
}
</style>
