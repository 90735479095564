<template>
    <div class="header-tabs">
        <rb-tab-set>
            <div
                v-if="activeTabInSession == 'id1'"
                slot="cta"
                style="display: flex"
            >
                <router-link :to="createPromotion">
                    <rb-button type="primary">Create Promotion</rb-button>
                </router-link>
                <div v-if="isCodeCampaign">
                    &nbsp;
                    <router-link :to="campaignCodeUpload">
                        <rb-button type="primary">Add Code(s)</rb-button>
                    </router-link>
                </div>
            </div>
            <div class="list">
                <rb-tab heading="Details" tab-id="id1">
                    <br />
                    <CampaignStatistics />
                </rb-tab>
                <rb-tab heading="Participation" tab-id="id2">
                    <br />
                    <ParticipationSearch />
                </rb-tab>
            </div>
        </rb-tab-set>
    </div>
</template>

<script lang="ts">
import ParticipationSearch from '@/components/ParticipationSearch.vue'
import CampaignStatistics from '@/components/CampaignStatistics.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CampaignTabs',
    components: {
        ParticipationSearch,
        CampaignStatistics,
    },
    mounted() {
        var campaignKey = this.$route.params.campaignKey as string
        this.$store.dispatch('retrieveStatsForCampaign', campaignKey)
    },
    computed: {
        activeTabInSession() {
            const activeOverviewTab = this.$store.getters.getActiveOverviewTab
            return !activeOverviewTab || activeOverviewTab.length === 0
                ? 'id1'
                : activeOverviewTab
        },
        createPromotion() {
            return `/promotion_create/${this.$route.params.campaignKey}`
        },
        campaignCodeUpload() {
            return `/code_upload/${this.$route.params.campaignKey}`
        },
        isCodeCampaign() {
            const campaign = this.$store.getters.getCampaignByKey(
                this.$route.params.campaignKey
            )
            return (
                campaign != null &&
                `RECEIPT` != campaign.supportedRedemptionType
            )
        },
    },
    data() {
        return {
            campaignKey: '',
        }
    },
    methods: {
        tabSelect: function (event: CustomEvent<string>) {
            this.$store.commit('setActiveOverviewTab', event.detail)
        },
    },
})
</script>

<style scoped></style>
