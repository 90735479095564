<template>
    <rb-card :heading="campaignHeading">
        <div slot="info">{{ campaign!.description }}</div>
        <div slot="cta">
            <router-link :to="detailsLink">
                <rb-button class="margin-right" type="primary" size="small">
                    Details
                </rb-button>
            </router-link>
        </div>
    </rb-card>
</template>

<script lang="ts">
import { Campaign } from '@/models/campaign.model'
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        campaign: Object as () => Campaign,
    },
    computed: {
        campaignHeading() {
            const campaign = this.campaign as Campaign
            return `${campaign.name} (${campaign.campaignKey})`
        },
        detailsLink() {
            const campaign = this.campaign as Campaign
            return `/campaign/${campaign.campaignKey}`
        },
    },
})
</script>
