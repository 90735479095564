<template>
    <div class="content-box card-box">
        <span>Leaderboard Rankings</span>
        <br />
        <div class="text-input">
            <rb-text weight="bold">Limit to top ranks:</rb-text>
            <rb-input
                v-on:inputChanged="limitChanged"
                :value.prop="limit"
                required="true"
                placeholder="Enter the number of top ranks to fetch"
            ></rb-input>
            <rb-button
                @click="refreshRankings()"
                class="margin-right refresh-button"
                type="secondary"
                size="small"
                >Load and Refresh
            </rb-button>
        </div>
        <br />
        <span v-if="entries">
            <JsonCSV :name="csvFileName()" :data="entries">
                Download Data
                <rb-icon name="loader" />
            </JsonCSV>
        </span>
        <div>
            <rb-table :columns.prop="selectColumns" :data.prop="entries" />
        </div>
    </div>
</template>

<script lang="ts">
import JsonCSV from 'vue-json-csv'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'LeaderboardRankingsComponent',
    components: {
        JsonCSV,
    },
    data: function () {
        return {
            limit: 10,
            entries: undefined as any[] | undefined,
            defaultColumns: [
                {
                    title: 'Rank',
                    field: 'rank',
                },
                {
                    title: 'UserId',
                    field: 'user',
                },
                {
                    title: 'Score',
                    field: 'score',
                },
            ],
            riotColumns: [
                {
                    title: 'Rank',
                    field: 'rank',
                },
                {
                    title: 'UserId',
                    field: 'user',
                },
                {
                    title: 'Score',
                    field: 'score',
                },
                {
                    title: 'Summoner Name',
                    field: 'summonerName',
                },
                {
                    title: 'Summoner ID',
                    field: 'summonerId',
                },
                {
                    title: 'Region',
                    field: 'region',
                },
            ],
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        refreshRankings: async function () {
            this.entries = await this.$store.dispatch(
                'fetchPromotionRankings',
                {
                    campaignKey: this.campaignKey,
                    promotionKey: this.promotionKey,
                    limit: this.limit,
                }
            )
        },
        csvFileName: function (): string {
            return `rankings-${this.campaignKey}-${this.promotionKey}-${Date.now()}.csv`
        },
        limitChanged: function (event: CustomEvent<number>) {
            this.limit = event.detail
        },
    },
    computed: {
        selectColumns() {
            if (
                this.$data.entries === undefined ||
                this.$data.entries[0].summonerId === undefined
            ) {
                return this.$data.defaultColumns
            } else {
                return this.$data.riotColumns
            }
        },
    },
})
</script>

<style scoped></style>
