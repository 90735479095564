<template>
    <div class="content-box card-box">
        <div class="top-container" style="justify-content: flex-end">
            <router-link :to="overviewLink">
                <rb-button class="margin-right" type="primary" size="small"
                    >Back</rb-button
                >
            </router-link>
        </div>
        <rb-heading weight="bold" size="large">Create a new User</rb-heading>
        <br /><br />
        <div class="top-container">
            <div style="width: 45%; justify-content: flex-start">
                <rb-heading weight="bold">Username:</rb-heading>
                <rb-input
                    v-on:inputChanged="inputChanged"
                    placeholder="Enter Username here"
                    required="true"
                ></rb-input>
            </div>
            <div style="width: 45%; justify-content: flex-end">
                <rb-heading weight="bold">Campaign-Access:</rb-heading>
                <div v-for="campaign in campaignList" v-bind:key="campaign.id">
                    <rb-checkbox
                        :label="campaign.name"
                        @changed="onSelectChanged($event, campaign)"
                    />
                </div>
            </div>
        </div>
        <div>
            <rb-button
                @click="saveUser()"
                class="margin-right"
                type="primary"
                size="small"
                >Save</rb-button
            >
        </div>
    </div>
</template>

<script lang="ts">
import { CreateManagementUser } from '@/models/createmanagementuser.model'
import { Campaign } from '@/models/campaign.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ManagementUserCreator',
    data() {
        return {
            overviewLink: '/',
            userName: '',
            campaignKeys: Array.of<string>(),
        }
    },
    methods: {
        saveUser() {
            this.userName = this.userName.trim()
            if (!this.userName || 0 === this.userName.length) {
                this.$store.commit('setError', 'Username is required')
                return
            }
            this.$store
                .dispatch(
                    'createUser',
                    new CreateManagementUser(this.userName, this.campaignKeys)
                )
                .then(() => {
                    this.$router.push('/')
                    this.$store.commit('setInfo', 'Created User')
                })
        },
        inputChanged: function (event: CustomEvent<string>) {
            this.userName = event.detail
        },
        onSelectChanged: function (
            event: CustomEvent<Boolean>,
            campaign: Campaign
        ) {
            if (event.detail) {
                this.campaignKeys.push(campaign.campaignKey)
            } else {
                this.campaignKeys = this.campaignKeys.filter(function (item) {
                    return item !== campaign.campaignKey
                })
            }
        },
    },
    computed: {
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list ?? []
        },
    },
})
</script>

<style scoped>
tr:nth-child(odd) {
    background: #ccc;
}
tr:nth-child(even) {
    background: #fff;
}
</style>
