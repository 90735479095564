<template>
    <div class="content-box card-box">
        <span>Redemptions Export</span>
        <br />
        <div class="text-input">
            <rb-text weight="bold"
                >Minimum number of redemptions each user must have:</rb-text
            >
            <rb-input
                v-on:inputChanged="minimumNumberOfRedemptionsChanged"
                :value.prop="minimumNumberOfRedemptions"
                required="true"
                placeholder="Enter minimum number of redemptions each user must have"
            ></rb-input>
            <rb-text weight="bold">Number of entries to be delivered:</rb-text>
            <rb-input
                v-on:inputChanged="limitChanged"
                :value.prop="limit"
                required="true"
                placeholder="Enter number of entries to be delivered"
            ></rb-input>
            <rb-button
                @click="refreshRedemptions()"
                class="margin-right refresh-button"
                type="secondary"
                size="small"
                >Load and Refresh
            </rb-button>
        </div>
        <br />
        <span v-if="entries" style="cursor: pointer">
            <JsonCSV :name="csvFileName()" :data="entries">
                Download Data
                <rb-icon name="loader" />
            </JsonCSV>
        </span>
        <div>
            <rb-table :columns.prop="selectColumns" :data.prop="entries" />
        </div>
    </div>
</template>

<script lang="ts">
import JsonCSV from 'vue-json-csv'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'LeaderboardRankingsComponent',
    components: {
        JsonCSV,
    },
    data: function () {
        return {
            minimumNumberOfRedemptions: 2,
            limit: 10,
            entries: undefined,
            defaultColumns: [
                {
                    title: 'UserId',
                    field: 'uimUserId',
                },
                {
                    title: 'Number of redemptions',
                    field: 'redemptionCount',
                },
            ],
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        refreshRedemptions: function () {
            this.$store
                .dispatch('fetchRedemptions', {
                    campaignKey: this.campaignKey,
                    promotionKey: this.promotionKey,
                    minimumNumberOfRedemptions: this.minimumNumberOfRedemptions,
                    limit: this.limit,
                })
                .then((result) => (this.entries = result))
        },
        csvFileName: function (): string {
            return `redemptions-${this.campaignKey}-${this.promotionKey}-${Date.now()}.csv`
        },
        minimumNumberOfRedemptionsChanged: function (
            event: CustomEvent<number>
        ) {
            this.minimumNumberOfRedemptions = event.detail
        },
        limitChanged: function (event: CustomEvent<number>) {
            this.limit = event.detail
        },
    },
    computed: {
        selectColumns() {
            return this.$data.defaultColumns
        },
    },
})
</script>

<style scoped></style>
