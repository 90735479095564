<template>
    <div class="content-box card-box">
        <div v-if="isError">
            <p class="input-error">{{ errorMsg }}</p>
        </div>
        <div>
            <rb-button
                @click="goBack()"
                class="margin-right"
                type="primary"
                size="small"
                >Back
            </rb-button>
            <rb-button
                @click="showSingleCodeUpload"
                type="primary"
                size="small"
                style="margin-right: 5px"
                >add single Code
            </rb-button>
            <rb-button
                @click="showFileUpload"
                type="primary"
                size="small"
                style="margin-right: 5px"
                >upload Codesfile
            </rb-button>
        </div>
        <br />
        <div>
            <span v-if="campaignStatistics.existingCodes">
                <span
                    >The campaign already contains codes. New codes will be
                    added to the existing ones.</span
                ><br />
                <span
                    >Please keep in mind that duplicated code entries will be
                    rejected and will cause the file upload to fail
                    completely.</span
                >
            </span>
            <span v-if="!campaignStatistics.existingCodes"
                >The campaign does not contain any codes yet.</span
            >
        </div>
        <br />
        <div v-if="isFileUploadSelected">
            <div style="margin-left: 3px">
                <rb-heading size="medium"
                    >Upload code file for Campaign</rb-heading
                >
                <br />
                <span
                    >Code files may contain up to 200k codes with one code per
                    line.<br />Each code must be at least 3 characters long and
                    not longer than 100.</span
                >
            </div>
            <br />
            <div class="file-input">
                <label
                    >File
                    <input
                        type="file"
                        id="file"
                        ref="fileInput"
                        v-on:change="updateFile"
                    />
                </label>
            </div>
            <div class="text-input">
                <rb-text weight="bold">Country:</rb-text>
                <rb-input
                    v-on:inputChanged="countryCode"
                    placeholder="Target Country, or empty"
                    :validation.prop="countryValidation"
                    :value="countryCode"
                    required="false"
                ></rb-input>
            </div>
            <div v-if="codeFile">
                <rb-button
                    @click="uploadCodes()"
                    class="margin-right"
                    type="primary"
                    size="small"
                    >Upload Code File
                </rb-button>
            </div>
        </div>
        <br />
        <div v-if="isSingleCodeUploadSelected">
            <div>
                <rb-heading size="medium"
                    >Add a singe code for testing</rb-heading
                >
                <br />
                <span
                    >Any codes added this way will be usable by anybody.
                    <br />Please make sure to consume them as soon as
                    possible.</span
                >
            </div>
            <div class="text-input">
                <rb-text weight="bold">Code:</rb-text>
                <rb-input
                    v-on:inputChanged="singleCodeChanged"
                    placeholder="Code to be added to campaign."
                    :validation.prop="codeValidation"
                    required="true"
                ></rb-input>
            </div>
            <div class="text-input">
                <rb-text weight="bold">Metadata:</rb-text>
                <rb-input
                    v-on:inputChanged="singleMetadataChanged"
                    placeholder="Metadata for the code, or empty"
                    :value="singleCode.metadata"
                    :validation.prop="metadataValidation"
                    required="false"
                ></rb-input>
            </div>
            <div class="text-input">
                <rb-text weight="bold">Country:</rb-text>
                <rb-input
                    v-on:inputChanged="singleCountryChanged"
                    placeholder="Target Country, or empty"
                    :value="singleCode.country"
                    :validation.prop="countryValidation"
                    required="false"
                ></rb-input>
            </div>
            <div>
                <rb-button
                    @click="addCode()"
                    class="margin-right"
                    type="primary"
                    size="small"
                    >Add Code
                </rb-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getFirstParamValue } from '@/commons/route.utils'

export default defineComponent({
    name: 'ManagementCodeUpload',
    data() {
        return {
            campaignKey: '',
            codeFile: null as null | File,
            countryCode: 'ZZ',
            isError: false,
            errorMsg: '',
            countryValidation: {
                regex: '^[A-Z]{2}$',
                errorMessage: 'Invalid ISO CountryCode',
            },
            metadataValidation: {
                regex: '.',
                errorMessage: 'Invalid Metadata',
            },
            codeValidation: {
                regex: '^[a-zA-Z0-9äÄöÖüÜ\\-\\.]{3,100}$',
                errorMessage: 'Invalid Code pattern.',
            },
            singleCode: {
                code: '',
                country: 'ZZ',
                metadata: '',
            },
            selectedSub: '',
        }
    },
    mounted() {
        this.campaignKey = getFirstParamValue(this.$route.params.campaignKey)
        this.$store.dispatch('retrieveStatsForCampaign', this.campaignKey)
    },
    methods: {
        updateFile() {
            const fileInput = this.$refs.fileInput as HTMLInputElement
            if (fileInput.files && fileInput.files[0].size > 2000000) {
                this.$store.commit(
                    'setError',
                    'You are trying to upload too many codes, please contact a "Code Redemption Admin"'
                )
                this.codeFile = null
            } else {
                this.codeFile = fileInput.files?.item(0)
            }
        },
        uploadCodes() {
            let loader = this.$loading.show({
                isFullPage: true,
                canCancel: false,
            })

            let dto = {
                codeFile: this.codeFile,
                country: this.countryCode,
                campaignKey: this.campaignKey,
            }
            this.$store
                .dispatch('uploadCodeFile', dto)
                .then(() => {
                    this.$store.dispatch(
                        'fetchStatsForCampaign',
                        this.campaignKey
                    )
                    this.goBack()
                })
                .finally(() => {
                    loader.hide()
                })
        },
        singleCodeChanged: function (event: CustomEvent<string>) {
            this.singleCode.code = event.detail
        },
        singleMetadataChanged: function (event: CustomEvent<string>) {
            this.singleCode.metadata = event.detail
        },
        singleCountryChanged: function (event: CustomEvent<string>) {
            this.singleCode.country = event.detail
        },
        addCode() {
            let loader = this.$loading.show({
                isFullPage: true,
                canCancel: false,
            })
            let dto = {
                code: this.singleCode.code,
                metadata: this.singleCode.metadata,
                country: this.singleCode.country,
                campaignKey: this.campaignKey,
            }
            this.$store
                .dispatch('addSingeCodeToCampaign', dto)
                .then(() => {
                    this.$store.dispatch(
                        'fetchStatsForCampaign',
                        this.campaignKey
                    )
                    this.goBack()
                })
                .finally(() => {
                    loader.hide()
                })
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
        showSingleCodeUpload() {
            this.selectedSub = 'SINGLE'
        },
        showFileUpload() {
            this.selectedSub = 'FILE'
        },
    },
    computed: {
        campaignStatistics() {
            return this.$store.getters.getStatisticsForCampaign(
                this.$data.campaignKey
            )
        },
        isSingleCodeUploadSelected() {
            return this.$data.selectedSub == 'SINGLE'
        },
        isFileUploadSelected() {
            return this.$data.selectedSub == 'FILE'
        },
    },
})
</script>

<style scoped></style>
