<template>
    <tr>
        <td>{{ interaction!.code }}</td>
        <td>{{ interaction!.promotionKey }}</td>
        <td>{{ interaction!.campaignKey }}</td>
        <td>{{ interaction!.action }}</td>
        <td>{{ interaction!.status }}</td>
        <td>{{ interaction!.createdAt }}</td>
        <td>{{ interaction!.httpStatus }}</td>
    </tr>
</template>

<script lang="ts">
import { UserInteraction } from '@/models/userInteraction.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UserInteractionItem',
    props: {
        interaction: Object as () => UserInteraction,
    },
})
</script>

<style scoped>
tr {
    text-align: center;
}
</style>
