<template>
    <div class="content-box card-box">
        <rb-heading weight="bold" size="large"
            >Create a new Campaign
        </rb-heading>
        <br /><br />
        <div class="text-input">
            <rb-text weight="bold">Name:</rb-text>
            <rb-input
                @inputChanged="nameChange"
                ref="input1"
                placeholder="Enter name here"
                required="true"
            ></rb-input>
        </div>
        <br />
        <div class="text-input">
            <rb-text weight="bold">CampaignKey:</rb-text>
            <rb-input
                @inputChanged="keyChange"
                ref="input2"
                placeholder="Enter campaignKey here"
                required="true"
            ></rb-input>
        </div>
        <br />
        <div class="text-input">
            <rb-text weight="bold">Description:</rb-text>
            <rb-input
                @inputChanged="descriptionChange"
                ref="input3"
                placeholder="Enter description here"
                required="true"
            ></rb-input>
        </div>
        <br />
        <div class="text-input">
            <rb-text weight="bold">ProviderName:</rb-text>
            <rb-input
                @inputChanged="providerChange"
                placeholder="Enter providerName here"
            ></rb-input>
        </div>
        <br /><br />
        <div>
            <rb-heading weight="bold">Settings:</rb-heading>
            <br />
            <rb-checkbox
                label="Rate-Limit"
                :value="rateLimitEnabled"
                @changed="rateLimitEnabled = $event.detail"
            />
            <rb-checkbox
                label="Recaptcha"
                :value="recaptchaEnabled"
                @changed="recaptchaEnabled = $event.detail"
            />
            <rb-checkbox
                label="IP-Limit"
                :value="ipLimitEnabled"
                @changed="ipLimitEnabled = $event.detail"
            />
            <rb-checkbox
                label="External Confirmation"
                :value="externalConfirmationRequired"
                @changed="externalConfirmationRequired = $event.detail"
            />
            <br />
            <rb-select
                label="Redemption by"
                :value="redemptionType"
                :options.prop="[
                    { label: 'accept all types', id: 'ALL' },
                    { label: 'only Can Codes', id: 'CODE' },
                    { label: 'only Receipt Upload', id: 'RECEIPT' },
                ]"
                :required="true"
                :multi="false"
                :inline="true"
                @optionsSelected="redemptionType = $event.detail[0]"
            />
        </div>
        <br />
        <div v-if="isError">
            <p class="input-error">{{ errorMsg }}</p>
        </div>
        <div>
            <router-link :to="overviewLink">
                <rb-button class="margin-right" type="primary" size="small"
                    >Cancel
                </rb-button>
            </router-link>
            <rb-button
                @click="saveCampaign()"
                class="margin-right"
                type="primary"
                size="small"
                >Save
            </rb-button>
        </div>
    </div>
</template>

<script lang="ts">
import { createCampaign } from '@/models/createCampaign.model'
import { Campaign } from '@/models/campaign.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ManagementCampaignCreator',
    data() {
        return {
            overviewLink: '/',
            name: '',
            campaignKey: '',
            description: '',
            providerName: '',
            ipLimitEnabled: false,
            rateLimitEnabled: false,
            recaptchaEnabled: false,
            externalConfirmationRequired: false,
            redemptionType: '',
            isError: false,
            errorMsg: '',
        }
    },
    methods: {
        saveCampaign() {
            this.isError = false
            if (!this.name || 0 === this.name.length) {
                this.errorMsg = 'Name is required'
                this.isError = true
                return
            }
            if (!this.campaignKey || 0 === this.campaignKey.length) {
                this.errorMsg = 'CampaignKey is required'
                this.isError = true
                return
            }
            if (!this.redemptionType || 0 === this.redemptionType.length) {
                this.errorMsg = 'Redemption Type is required'
                this.isError = true
                return
            }
            const alreadyExisting = this.campaignList().filter(
                (value) => this.campaignKey === value.campaignKey
            )
            if (alreadyExisting.length != 0) {
                this.errorMsg =
                    'CampaignKey "' + this.campaignKey + '" already exists'
                this.isError = true
                return
            }
            if (!this.description || 0 === this.description.length) {
                this.errorMsg = 'Description is required'
                this.isError = true
                return
            }
            this.$store
                .dispatch(
                    'createCampaign',
                    new createCampaign(
                        this.campaignKey,
                        this.name,
                        this.description,
                        this.providerName,
                        this.ipLimitEnabled,
                        this.rateLimitEnabled,
                        this.recaptchaEnabled,
                        this.externalConfirmationRequired,
                        this.redemptionType
                    )
                )
                .then(() => {
                    this.$router.push('/')
                    this.$store.commit('setInfo', 'Created Campaign')
                    this.$store.dispatch('fetchCampaigns')
                })
        },
        nameChange: function (event: CustomEvent<string>) {
            this.name = event.detail
        },
        keyChange: function (event: CustomEvent<string>) {
            this.campaignKey = event.detail
        },
        descriptionChange: function (event: CustomEvent<string>) {
            this.description = event.detail
        },
        providerChange: function (event: CustomEvent<string>) {
            this.providerName = event.detail
        },
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list ?? []
        },
    },
})
</script>

<style scoped>
tr:nth-child(odd) {
    background: #ccc;
}

tr:nth-child(even) {
    background: #fff;
}
</style>
