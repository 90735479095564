<template>
    <div>
        <p>{{ text }}</p>
    </div>
</template>
<script lang="ts">
import { UserInfo } from '@/models/userInfo.model'
import { defineComponent } from 'vue'

export default defineComponent({
    mounted() {
        const redirectToFromQuery = this.$route.query.redirectTo
        if (this.$store.getters.hasValidHeaders) {
            this.$router.push(`${redirectToFromQuery}`).catch(() => {})
        } else {
            if (this.$store.state.auth.authActionImpending) {
                this.$nextTick(() => {
                    this.$store
                        .dispatch('handleAuthentication', redirectToFromQuery)
                        .then((destination: string) => {
                            if (this.isAuthorizedUser()) {
                                this.$router.push(destination).catch(() => {})
                            }
                        })
                })
            } else {
                this.$store.dispatch('login', redirectToFromQuery)
            }
        }
    },
    computed: {
        text(): string {
            if (
                this.$store.state.auth.authActionImpending ||
                this.$store.getters.hasValidHeaders
            ) {
                if (!this.isAuthorizedUser()) {
                    return 'You are not authorized to use Code Redemption'
                }
                return 'loading...'
            } else {
                return 'Please log in'
            }
        },
    },
    methods: {
        isAuthorizedUser(): boolean {
            const userInfo: UserInfo = this.$store.getters.getUserInfo
            return userInfo.isAdmin || userInfo.isAppOwner
        },
    },
})
</script>
