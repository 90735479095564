<template>
    <div class="card-box">
        <br />
        <rb-search
            v-on:searchChange="searchChanged"
            size="small"
            type="default"
            placeholder="uimUserId"
            :value="searchParam"
        ></rb-search>

        <UserInteractionList />
    </div>
</template>

<script lang="ts">
import UserInteractionList from '@/components/UserInteractionList.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UserInteractionSearch',
    components: {
        UserInteractionList,
    },
    data() {
        return {
            timeout: undefined as NodeJS.Timeout | undefined,
            searchParam: '',
        }
    },
    mounted() {
        this.searchParam = this.userInteraction.searchTerm
    },
    computed: {
        userInteraction() {
            return this.$store.getters.getUserInteractionSearch()
        },
    },
    methods: {
        triggerSearch: function (searchParam: string) {
            if (searchParam !== this.userInteraction.searchTerm) {
                this.$store.dispatch('searchForUserInteraction', searchParam)
            }
        },
        searchChanged: function (event: CustomEvent<string>) {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.triggerSearch(event.detail)
            }, 500)
        },
    },
})
</script>

<style scoped></style>
