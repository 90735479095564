<template>
    <div>
        <br />
        <rb-heading weight="bold">Search results:</rb-heading>
        <table v-if="searchResultFound == 1" class="search-table" aria-label="">
            <tr>
                <th>Code</th>
                <th>Promotion</th>
                <th>Campaign</th>
                <th>Action</th>
                <th>Status</th>
                <th>Created</th>
                <th>Http-Status</th>
            </tr>
            <UserInteractionItem
                v-for="interaction in interactionList"
                v-bind:key="interactionKey(interaction)"
                :interaction="interaction"
            />
        </table>

        <div v-if="searchResultFound == 0" class="search-table no-result-row">
            No results found
        </div>
    </div>
</template>

<script lang="ts">
import UserInteractionItem from './UserInteractionItem.vue'
import { UserInteraction } from '@/models/userInteraction.model'
import { UserInteractionSearch } from '@/models/userInteractionSearch'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UserInteractionList',
    components: {
        UserInteractionItem,
    },
    computed: {
        userInteraction(): UserInteractionSearch {
            return this.$store.getters.getUserInteractionSearch()
        },
        interactionList(): UserInteraction[] | undefined {
            return this.userInteraction.interactions
        },
        searchResultFound(): number {
            if (this.userInteraction.searchTerm.length == 0) return -1
            if (this.interactionList && this.interactionList.length > 0)
                return 1
            if (this.interactionList && this.interactionList.length == 0)
                return 0
            return -1
        },
    },
    methods: {
        interactionKey: function (userInteraction: UserInteraction) {
            return userInteraction.code
                .concat('-', userInteraction.uimUserId)
                .concat('-', userInteraction.createdAt)
        },
    },
})
</script>

<style scoped>
.search-table {
    border: 1px solid black;
    width: 100%;
}

.no-result-row {
    text-align: center;
}
tr:nth-child(odd) {
    background: #ccc;
}
tr:nth-child(even) {
    background: #fff;
}
</style>
