import { Store, MutationTree, GetterTree, ActionTree, createStore } from 'vuex'
import { AuthState } from './auth/auth.state'
import { authMutations, authActions } from './auth/auth.state.operations'
import { ErrorState } from './error/error.state'
import {
    errGetters,
    errMutations,
    errActions,
} from './error/error.state.operations'
import { InfoState } from './info/info.state'
import {
    infoGetters,
    infoMutations,
    infoActions,
} from './info/info.state.operations'
import { DataState } from './data/data.state'
import {
    dataMutations,
    dataActions,
    dataGetters,
} from './data/data.state.operations'
import { LeaderboardState } from '@/state/leaderboard/leaderboard.state'
import {
    leaderboardActions,
    leaderboardGetters,
    leaderboardMutations,
} from '@/state/leaderboard/leaderboard.state.operations'

export interface RootState {
    auth: AuthState
    err: ErrorState
    data: DataState
    info: InfoState
    leaderboard: LeaderboardState
}

const state: RootState = {
    auth: new AuthState(),
    err: new ErrorState(),
    data: new DataState(),
    info: new InfoState(),
    leaderboard: new LeaderboardState(),
}

const getters: GetterTree<RootState, RootState> = {
    ...dataGetters,
    ...errGetters,
    ...infoGetters,
    ...leaderboardGetters,
}

const mutations: MutationTree<RootState> = {
    ...authMutations,
    ...errMutations,
    ...dataMutations,
    ...infoMutations,
    ...leaderboardMutations,
}

const actions: ActionTree<RootState, RootState> = {
    ...authActions,
    ...errActions,
    ...dataActions,
    ...infoActions,
    ...leaderboardActions,
}

export const appStore: Store<RootState> = createStore({
    state,
    getters,
    mutations,
    actions,
})
