import './styles/base.scss'
import { createApp } from 'vue'
import App from './App.vue'
import { appStore } from './state/store'
import { router } from './router/router'
import {
    applyPolyfills,
    defineCustomElements,
} from '@rbmh-design-system/web-components/loader'
import { LoadingPlugin } from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

// Vue.config.productionTip = false
;(async () => {
    const app = createApp(App)

    app.use(appStore)
    app.use(router)
    app.use(LoadingPlugin)

    await applyPolyfills()
    await defineCustomElements(window)

    await router.isReady()

    app.mount('#app')
})()
