<template>
    <div class="content-box card-box">
        <span>Sent Braze Campaign</span>
        <div class="text-input">
            <rb-input
                label="Braze Id"
                v-on:inputChanged="brazeCampaignIdChanged"
                :validation.prop="brazeValidation"
                required="true"
                placeholder=""
                ref="brazeIdInput"
                :value="id"
            />
        </div>
        <div class="text-input">
            <rb-text
                >Target Rankings, if left empty, will notify all ranked
                users.</rb-text
            >
            <rb-input
                label="From"
                v-on:inputChanged="brazeFromChanged"
                :validation.prop="numberValidation"
                placeholder="From Rank"
                ref="brazeFormInput"
                :value="from"
            />
            <rb-input
                label="To"
                v-on:inputChanged="brazeToChanged"
                :validation.prop="numberValidation"
                placeholder="To Rank"
                ref="brazeToInput"
                :value="to"
            />
        </div>
        <div class="text-input">
            <rb-input
                label="Delay before sending"
                v-on:inputChanged="brazeDelayChanged"
                :validation.prop="numberValidation"
                placeholder="Delay in milliseconds"
                ref="brazeDelayInput"
                :value="delay"
            />
        </div>
        <div>
            <rb-button @click="sendBrazeCampaign">Trigger Braze </rb-button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'LeaderboardBrazeComponent',
    data: function () {
        return {
            brazeValidation: {
                regex: '^[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}$',
                errorMessage: 'Invalid Braze ID pattern.',
            },
            numberValidation: {
                regex: '^[1-9]{1}[0-9]*$',
                errorMessage: 'Invalid number',
            },
            id: undefined,
            from: undefined,
            to: undefined,
            delay: undefined,
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        brazeCampaignIdChanged: function (event: CustomEvent) {
            this.id = event.detail == '' ? undefined : event.detail
        },
        brazeFromChanged: function (event: CustomEvent) {
            this.from = event.detail == '' ? undefined : event.detail
        },
        brazeToChanged: function (event: CustomEvent) {
            this.to = event.detail == '' ? undefined : event.detail
        },
        brazeDelayChanged: function (event: CustomEvent) {
            this.delay = event.detail == '' ? undefined : event.detail
        },
        sendBrazeCampaign() {
            var body = {
                campaignKey: this.campaignKey,
                promotionKey: this.promotionKey,
                brazeId: this.id,
                rankFrom: this.from,
                rankTo: this.to,
                delay: this.delay,
            }
            this.$store.dispatch('triggerBrazeCampaign', body)
        },
    },
})
</script>

<style scoped></style>
