<template>
    <div>
        <div class="top-container" style="justify-content: flex-end">
            <router-link :to="overviewLink">
                <rb-button class="margin-right" type="primary" size="small"
                    >Back</rb-button
                >
            </router-link>
        </div>
        <rb-heading weight="bold" size="large"
            >Manage campaign-access for User: {{ userName }}</rb-heading
        >
        <br />
        <rb-heading size="small"
            >Changes on this page take effect immediately</rb-heading
        >
        <div class="listContainer">
            <div
                v-bind:key="campaign.campaignKey"
                v-for="campaign in campaignList"
                class="listItem"
            >
                <rb-card :heading="campaign.name">
                    <div slot="info">{{ campaign.description }}</div>
                    <div slot="cta">
                        <rb-button
                            class="margin-right"
                            type="primary"
                            size="small"
                            v-if="hasAccess(campaign)"
                            @click="changeAccess(campaign, false)"
                            >Remove</rb-button
                        >
                        <rb-button
                            class="margin-right"
                            type="primary"
                            size="small"
                            v-else
                            @click="changeAccess(campaign, true)"
                            >Add</rb-button
                        >
                    </div>
                </rb-card>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ManagementUser } from '@/models/managementuser.model'
import { Campaign } from '@/models/campaign.model'
import { defineComponent } from 'vue'
import { getFirstParamValue } from '@/commons/route.utils'

export default defineComponent({
    name: 'ManagementUserEditor',
    data() {
        return {
            userId: '',
            overviewLink: '/',
        }
    },
    mounted() {
        this.userId = getFirstParamValue(this.$route.params.userId)
    },
    computed: {
        user(): ManagementUser {
            return this.$store.getters.getUserById(this.$data.userId)
        },
        userName(): string {
            const user = this.user as ManagementUser
            if (user) {
                return user.userName
            }
            return ''
        },
        campaignList(): Campaign[] {
            return this.$store.state.data.campaigns.list ?? []
        },
    },
    methods: {
        hasAccess: function (campaign: Campaign) {
            if (this.user) {
                return this.user.campaigns.find(
                    (elem) => elem.campaignKey === campaign.campaignKey
                )
            }
            return false
        },
        changeAccess: function (campaign: Campaign, granted: boolean) {
            this.$store.dispatch('changeAccess', {
                campaignKey: campaign.campaignKey,
                userName: this.userName,
                accessGranted: granted,
            })
        },
    },
})
</script>

<style scoped>
.listContainer {
    margin-top: 40px;
}
.listItem {
    width: 45%;
    min-width: 350px;
    float: left;
}
</style>
