<template>
    <rb-card :heading="userName">
        <div slot="info">Access to {{ numberOfCampaigns }} Campaigns.</div>
        <div slot="cta">
            <router-link :to="editUserLink">
                <rb-button class="margin-right" type="primary" size="small"
                    >Edit</rb-button
                >
            </router-link>
            <rb-button
                @click="deleteUser()"
                class="margin-right"
                type="primary"
                size="small"
                >Delete</rb-button
            >
        </div>
    </rb-card>
</template>

<script lang="ts">
import { ManagementUser } from '@/models/managementuser.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ManagementUserItem',
    props: {
        user: Object as () => ManagementUser,
    },
    methods: {
        deleteUser() {
            let c = confirm('Really delete user?')
            if (c) {
                this.$store.dispatch(
                    'deleteManagementUser',
                    this.user!.userName
                )
            }
        },
    },
    computed: {
        userName(): string {
            const user = this.user as ManagementUser
            return user.userName
        },
        numberOfCampaigns(): number {
            const user = this.user as ManagementUser
            return user.campaigns.length
        },
        editUserLink() {
            const user = this.user as ManagementUser
            return `/user/${user.id}`
        },
    },
})
</script>

<style scoped></style>
