<template>
    <div class="header-tabs">
        <rb-tab-set :selected="activeTabInSession" v-on:tabSelect="tabSelect">
            <div
                v-if="activeTabInSession == 'id3'"
                slot="cta"
                style="display: flex"
            >
                <router-link :to="addUserLink">
                    <rb-button type="primary">Add User</rb-button>
                </router-link>
            </div>
            <div
                v-if="activeTabInSession == 'id1'"
                slot="cta"
                style="display: flex"
            >
                <router-link :to="createCampaign">
                    <rb-button type="primary">Create Campaign</rb-button>
                </router-link>
            </div>
            <div class="list">
                <rb-tab heading="Campaigns" tab-id="id1">
                    <CampaignList />
                </rb-tab>
                <rb-tab heading="User-Interactions" tab-id="id2">
                    <UserInteractionSearch />
                </rb-tab>
                <span v-if="isAdmin">
                    <rb-tab heading="Admin-User-Management" tab-id="id3">
                        <ManagementUserList />
                    </rb-tab>
                </span>
            </div>
        </rb-tab-set>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CampaignList from './CampaignList.vue'
import ManagementUserList from './ManagementUserList.vue'
import UserInteractionSearch from './UserInteractionSearch.vue'
import { UserInfo } from '@/models/userInfo.model'

export default defineComponent({
    name: 'OverviewTabs',
    components: {
        UserInteractionSearch,
        CampaignList,
        ManagementUserList,
    },
    computed: {
        activeTabInSession() {
            const activeOverviewTab = this.$store.getters.getActiveOverviewTab
            return !activeOverviewTab || activeOverviewTab.length === 0
                ? 'id1'
                : activeOverviewTab
        },
        createCampaign() {
            return '/campaign_create'
        },
        addUserLink() {
            return '/user_create'
        },
        isAdmin() {
            const userInfo: UserInfo = this.$store.getters.getUserInfo
            return userInfo.isAdmin
        },
    },
    methods: {
        tabSelect: function (event: CustomEvent<string>) {
            this.$store.commit('setActiveOverviewTab', event.detail)
        },
    },
})
</script>

<style scoped></style>
