import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '../store'
import { AuthHeaders } from './auth.state'
import { WebAuthOperations, ParsedAuthHash } from '@/commons/auth.utils'
import { ROUTING } from '@/commons/constants'
import { StorageUtils } from '@/commons/storage.utils'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { UserInfo } from '@/models/userInfo.model'
import { HeaderUtils } from '@/state/headers.utils'

export const authMutations: MutationTree<RootState> = {
    setToLoading: (state: RootState) => {
        StorageUtils.setAuthLoadingInProgress()
        state.auth = { ...state.auth, authActionImpending: true }
    },
    setExpiresAt: (state: RootState, expiresAt: number) => {
        StorageUtils.setAuthAccessTokenExpiresAt(expiresAt)
        state.auth = { ...state.auth, expiresAt }
    },
    setAccessToken: (state: RootState, accessToken: string) => {
        StorageUtils.setAuthAccessToken(accessToken)
        state.auth = { ...state.auth, accessToken }
    },
    setAuthHeaders: (state: RootState, headers: AuthHeaders) => {
        StorageUtils.setAuthLoadingDone()
        state.auth = { ...state.auth, headers, authActionImpending: false }
    },
    clearAuth: (state: RootState) => {
        StorageUtils.cleanseAuth()
        state.auth = {
            accessToken: undefined,
            headers: undefined,
            expiresAt: -1,
            authActionImpending: false,
        }
    },
}

export const authActions: ActionTree<RootState, RootState> = {
    handleAuthentication: ({ commit, state }, desiredDestination) => {
        return new Promise((resolve, reject) => {
            if (WebAuthOperations.hasValidHeaders(state)) {
                resolve(undefined)
            }
            let actualDestination: string
            let accessTokenPromise: Promise<string | void>
            if (WebAuthOperations.hasValidToken(state)) {
                actualDestination = desiredDestination
                    ? desiredDestination
                    : ROUTING.DEFAULT_PATH
                accessTokenPromise = Promise.resolve(state.auth.accessToken)
            } else {
                actualDestination = StorageUtils.getRedirect()
                accessTokenPromise = WebAuthOperations.handleAuthentication()
                    .then((parsedAuthHash: ParsedAuthHash) => {
                        commit('setExpiresAt', parsedAuthHash.expiresAt)
                        commit('setAccessToken', parsedAuthHash.accessToken)
                        return parsedAuthHash.accessToken
                    })
                    .catch((err) => {
                        commit('clearAuth')
                        commit('setError', err)
                        reject(err)
                    })
            }
            return accessTokenPromise.then((accessToken: string | void) => {
                if (accessToken) {
                    return WebAuthOperations.setSession(accessToken)
                        .then((authHeaders: AuthHeaders) => {
                            commit('setAuthHeaders', authHeaders)

                            return axios
                                .get(
                                    process.env
                                        .VITE_APP_CODE_REDEMPTION_BASE_URL +
                                        '/mgmt/userinfo',
                                    {
                                        headers:
                                            HeaderUtils.constructHeadersMap(
                                                state.auth.headers
                                            ),
                                    }
                                )
                                .then((result: AxiosResponse) => {
                                    const userInfo: UserInfo = {
                                        isAdmin: result.data.isAdmin,
                                        isAppOwner: result.data.isAppOwner,
                                    }
                                    commit('setUserInfo', userInfo)
                                    resolve(actualDestination)
                                })
                                .catch((err: AxiosError) => {
                                    commit('setError', err.message)
                                })
                        })
                        .catch((err) => {
                            commit('clearAuth')
                            commit('setError', err)
                            reject(err)
                        })
                }
            })
        })
    },
    login: ({ commit }, desiredDestination) => {
        StorageUtils.setRedirect(desiredDestination)
        commit('setToLoading')
        WebAuthOperations.authorize()
    },
    logout: ({ commit }) => {
        commit('clearAuth')
        WebAuthOperations.logout()
    },
}
