import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordRaw,
} from 'vue-router'
import AuthCallback from '@/components/AuthCallback.vue'
import CampaignTabs from '@/components/CampaignDetailsTabs.vue'
import OverviewTabs from '@/components/OverviewTabs.vue'
import NotFound from '@/components/NotFound.vue'
import { ROUTING } from '@/commons/constants'
import { appStore } from '@/state/store'
import ManagementUserCreator from '@/components/ManagementUserCreator.vue'
import ManagementUserEditor from '@/components/ManagementUserEditor.vue'
import ManagementCampaignCreator from '@/components/ManagementCampaignCreator.vue'
import ManagementPromotionCreator from '@/components/ManagementPromotionCreator.vue'
import ManagementPromotionUpdater from '@/components/ManagementPromotionUpdater.vue'
import ManagementPromotionDetails from '@/components/ManagementPromotionDetails.vue'
import ManagementCodeUpload from '@/components/ManagementCodeUpload.vue'
import LogoutPage from '@/components/LogoutPage.vue'
import { WebAuthOperations } from '@/commons/auth.utils'

const AUTH_CALLBACK = '/'

const securedPageGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    if (to.path === '/logout') {
        next()
        return
    }

    const validTokens = WebAuthOperations.isUserAuthenticated(appStore.state)
    if (to.path !== AUTH_CALLBACK && !validTokens) {
        next({ path: AUTH_CALLBACK, query: { redirectTo: to.fullPath } })
        return
    }
    if (to.path == AUTH_CALLBACK && validTokens) {
        to.query.redirectTo = ROUTING.DEFAULT_PATH
        next()
        return
    }
    next()
}

const routes: RouteRecordRaw[] = [
    {
        path: '/overview',
        component: OverviewTabs,
    },
    {
        path: AUTH_CALLBACK,
        component: AuthCallback,
    },
    {
        path: '/logout',
        component: LogoutPage,
    },
    {
        path: '/campaign/:campaignKey',
        component: CampaignTabs,
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
    },
    {
        path: '/user_create',
        component: ManagementUserCreator,
    },
    {
        path: '/user/:userId',
        component: ManagementUserEditor,
    },
    {
        path: '/campaign_create',
        component: ManagementCampaignCreator,
    },
    {
        path: '/promotion_create/:campaignKey',
        component: ManagementPromotionCreator,
    },
    {
        path: '/promotion_update/:campaignKey/:promotionKey',
        component: ManagementPromotionUpdater,
    },
    {
        path: '/promotion_details/:campaignKey/:promotionKey',
        component: ManagementPromotionDetails,
    },
    {
        path: '/code_upload/:campaignKey',
        component: ManagementCodeUpload,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => securedPageGuard(to, from, next))

export { router }
