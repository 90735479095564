<template>
    <div class="error">
        <p style="margin-left: 10%">{{ errorText }}</p>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    mounted() {
        this.$store.dispatch('clearError')
    },
    computed: {
        errorText(): string {
            return `Error occured: ${this.$store.state.err.latest}`
        },
    },
})
</script>
<style lang="scss" scoped>
.error {
    background-color: lightcoral;
    min-width: 100%;
    overflow: hidden;
}
</style>
