<template>
    <tr>
        <td>{{ participation!.uimUserId }}</td>
        <td>{{ participation!.code }}</td>
        <td>{{ participation!.promotion }}</td>
        <td>{{ participation!.redeemedAt }}</td>
        <td>{{ participation!.redeemCount }}</td>
        <td>{{ participation!.externalConfirmation }}</td>
        <td>{{ participation!.additionalInfo }}</td>
    </tr>
</template>

<script lang="ts">
import { Participation } from '@/models/participation.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ParticipationItem',
    props: {
        participation: Object as () => Participation,
    },
})
</script>

<style scoped>
tr {
    text-align: center;
}
</style>
