<template>
    <div>
        <div class="top-container" style="justify-content: flex-end">
            <rb-button
                @click="refreshUsers()"
                class="margin-right refresh-button"
                type="secondary"
                size="small"
                >Refresh</rb-button
            >
        </div>
        <div class="header-box">
            <rb-heading weight="bold">Management Users:</rb-heading>
        </div>
        <p v-if="isLoading" class="loading-indicator">Loading...</p>
        <div v-else-if="noUserFound" style="text-align: center">
            <br />
            No Users found
        </div>
        <ul class="user-list" v-else>
            <ManagementUserItem
                v-for="user in userList"
                v-bind:key="user.id"
                :user="user"
            />
        </ul>
    </div>
</template>

<script lang="ts">
import ManagementUserItem from './ManagementUserItem.vue'
import { ManagementUser } from '@/models/managementuser.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ManagementUserList',
    components: {
        ManagementUserItem,
    },
    mounted() {
        this.$store.dispatch('fetchManagementUsers')
    },
    methods: {
        refreshUsers() {
            this.$store.dispatch('fetchManagementUsers')
        },
    },
    computed: {
        isLoading(): boolean {
            return this.$store.state.data.managementUsers.isLoading
        },
        userList(): ManagementUser[] {
            return this.$store.state.data.managementUsers.list ?? []
        },
        noUserFound(): boolean {
            return this.userList && this.userList.length === 0
        },
    },
})
</script>

<style scoped>
.user-list {
    padding-left: 0;
}
</style>
