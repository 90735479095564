<template>
    <div>
        <br />
        <rb-heading weight="bold">Search results:</rb-heading>
        <table v-if="searchResultFound == 1" class="search-table" aria-label="">
            <tr>
                <th>User</th>
                <th>Code</th>
                <th>Promotion</th>
                <th>Redeemed At</th>
                <th>RedeemCount</th>
                <th>Ext. Conf</th>
                <th>add. Info</th>
            </tr>
            <ParticipationItem
                v-for="participation in participationList"
                v-bind:key="participationKey(participation)"
                :participation="participation"
            />
        </table>

        <div v-if="searchResultFound == 0" class="search-table no-result-row">
            No results found
        </div>
    </div>
</template>

<script lang="ts">
import ParticipationItem from './ParticipationItem.vue'
import { Participation } from '@/models/participation.model'
import { Campaign } from '@/models/campaign.model'
import { defineComponent } from 'vue'
import { getFirstParamValue } from '@/commons/route.utils'

export default defineComponent({
    name: 'ParticipationList',
    components: {
        ParticipationItem,
    },
    data() {
        return {
            campaignKey: '',
        }
    },
    mounted() {
        this.campaignKey = getFirstParamValue(this.$route.params.campaignKey)
    },
    computed: {
        campaign(): Campaign {
            return this.$store.getters.getCampaignByKey(this.$data.campaignKey)
        },
        searchByUserResultFound(): number {
            if (this.campaign) {
                if (
                    this.campaign.participationSearchByUimUserResult &&
                    this.campaign.participationSearchByUimUserResult.length > 0
                )
                    return 1
                if (
                    this.campaign.participationSearchByUimUserResult &&
                    this.campaign.participationSearchByUimUserResult.length == 0
                )
                    return 0
            }
            return -1
        },
        searchByCodeResultFound(): number {
            if (this.campaign) {
                if (
                    this.campaign.participationSearchByCodeResult &&
                    this.campaign.participationSearchByCodeResult.length > 0
                )
                    return 1
                if (
                    this.campaign.participationSearchByCodeResult &&
                    this.campaign.participationSearchByCodeResult.length == 0
                )
                    return 0
            }
            return -1
        },
        searchResultFound(): number {
            if (
                this.searchByCodeResultFound == 1 ||
                this.searchByUserResultFound == 1
            )
                return 1
            if (
                this.searchByCodeResultFound == -1 &&
                this.searchByUserResultFound == -1
            )
                return -1
            return 0
        },
        participationList(): Participation[] {
            if (this.searchByUserResultFound) {
                return this.campaign.participationSearchByUimUserResult
            } else if (this.searchByCodeResultFound) {
                return this.campaign.participationSearchByCodeResult
            }
            return []
        },
    },
    methods: {
        participationKey: function (participation: Participation): string {
            return participation.uimUserId.concat('-', participation.code)
        },
    },
})
</script>

<style scoped>
.search-table {
    border: 1px solid black;
    width: 100%;
}

.no-result-row {
    text-align: center;
}
tr:nth-child(odd) {
    background: #ccc;
}
tr:nth-child(even) {
    background: #fff;
}
</style>
