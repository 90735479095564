<template>
    <div>
        <br />
        <div style="margin-left: 3px">
            <rb-heading size="medium"
                >Promotion {{ stats.promotionName }} ({{
                    stats.promotionKey
                }})</rb-heading
            >
        </div>
        <div class="card-box">
            <rb-heading size="x-small">Description: </rb-heading>
            <rb-heading size="medium">{{ stats.description }}</rb-heading>
            <br />
            <rb-heading size="x-small">Max Redemption per user: </rb-heading>
            <rb-heading size="medium">{{
                stats.maxRedemptionsPerUser
            }}</rb-heading>
            <br />
            <rb-heading size="x-small">Active: </rb-heading>
            <rb-heading>{{ promotionState() ? 'YES' : 'NO' }}</rb-heading>
            <rb-heading size="medium">
                ({{ stats.validFrom }}<rb-icon name="arrow-right" />&nbsp;{{
                    stats.validUntil
                }})
            </rb-heading>
        </div>
        <div class="card-box">
            <rb-heading>User redemptions</rb-heading>
            <br />
            <rb-heading size="small">
                Total:
                {{ stats.redemptionCount + stats.nonConfirmedRedemptionCount }}
                <br />
                <div v-if="stats.unconfirmedPossible">
                    Unique: {{ stats.redemptionCount }}
                </div>
            </rb-heading>
        </div>
        <div class="card-box">
            <rb-heading>Leaderboard</rb-heading>
            <br />
            <span v-if="hasLeaderboard">
                <rb-heading size="small"
                    >Participants: {{ leaderBoardParticipants }}</rb-heading
                >
                <br />
            </span>
            <span v-else
                ><rb-text>No Leaderboard connected to promotion.</rb-text></span
            >
        </div>
        <br />
        <router-link :to="campaignLink()">
            <rb-button class="margin-right" type="primary" size="small"
                >Back to campaign
            </rb-button>
        </router-link>
    </div>
</template>

<script lang="ts">
import { PromotionStatistics } from '@/models/promotionStatistics.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'PromotionDetailsOverviewComponent',
    props: [
        'campaignKey',
        'stats',
        'hasLeaderboard',
        'leaderBoardParticipants',
    ],
    methods: {
        campaignLink() {
            const ck = this.campaignKey
            return `/campaign/${ck}`
        },
        promotionState() {
            const stats = this.stats as PromotionStatistics
            const now = Date.now()
            return (
                Date.parse(stats.validFrom) < now &&
                now < Date.parse(stats.validUntil)
            )
        },
    },
})
</script>

<style scoped></style>
