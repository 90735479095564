<template>
    <div>
        <div style="margin-left: 3px">
            <rb-heading size="medium">Campaign</rb-heading>
        </div>
        <div class="card-box">
            <div v-if="!isLoading">
                <rb-heading size="large"
                    >{{ campaignName }} ({{ campaignKey }})</rb-heading
                >
                <div class="button">
                    <router-link :to="overviewLink">
                        <rb-button
                            type="primary"
                            size="small"
                            style="margin-right: 37px"
                            >Back</rb-button
                        >
                    </router-link>
                </div>
                <div style="padding-bottom: 5px; margin-top: -20px">
                    <rb-heading size="small">
                        <span v-if="campaign.providerName"
                            >Provider: {{ campaign.providerName }} &nbsp; |
                            &nbsp;
                        </span>
                        Redemption Type :
                        {{ campaign.supportedRedemptionType }} |
                        <span v-if="isCodeCampaign"
                            >Contains Codes:
                            {{
                                campaignStatistics.existingCodes ? 'YES' : 'NO'
                            }}
                            |
                        </span>
                        Overall redemptions: {{ redemptionCount }}
                    </rb-heading>
                </div>
                <div id="checkboxes">
                    <rb-checkbox
                        v-bind:value="campaign.rateLimitEnabled"
                        label="Rate-Limit"
                        disabled="true"
                    />
                    <rb-checkbox
                        v-bind:value="campaign.recaptchaEnabled"
                        label="Recaptcha"
                        disabled="true"
                    />
                    <rb-checkbox
                        v-bind:value="campaign.ipLimitEnabled"
                        label="IP-Limit"
                        disabled="true"
                    />
                    <rb-checkbox
                        v-bind:value="campaign.externalConfirmationRequired"
                        label="External Confirmation"
                        disabled="true"
                    />
                </div>
            </div>
        </div>
        <div>
            <div id="refresh-button" class="button">
                <rb-button @click="refreshStats()" type="secondary" size="small"
                    >Refresh
                </rb-button>
            </div>
            <div style="margin-top: -27px; margin-left: 3px">
                <rb-heading size="medium">Promotions</rb-heading>
            </div>
        </div>
        <div v-if="!isLoading">
            <ul>
                <PromotionStatistics
                    v-for="item in campaignStatistics.promotionStats"
                    :key="item.promotionName"
                    :stats="item"
                />
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import { Campaign } from '../models/campaign.model'
import PromotionStatistics from './PromotionStatistics.vue'
import { CampaignStatistics } from '../models/campaignStatistics.model'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CampaignStatistics',
    components: {
        PromotionStatistics,
    },
    data() {
        return {
            campaignKey: '',
            overviewLink: '/',
        }
    },
    mounted() {
        this.campaignKey = this.$route.params.campaignKey as string
    },
    methods: {
        refreshStats() {
            this.$store.dispatch('fetchStatsForCampaign', this.campaignKey)
        },
    },
    computed: {
        isLoading(): boolean {
            const campaign = this.campaignStatistics as CampaignStatistics
            return campaign === undefined
        },
        redemptionCount(): number {
            const campaign = this.campaignStatistics as CampaignStatistics
            return campaign.redemptionCount
        },
        campaign() {
            return this.$store.getters.getCampaignByKey(this.$data.campaignKey)
        },
        campaignStatistics() {
            return this.$store.getters.getStatisticsForCampaign(
                this.$data.campaignKey
            )
        },
        campaignName() {
            let campaign = this.campaign as Campaign
            return campaign ? campaign.name : 'Loading...'
        },
        isCodeCampaign() {
            const campaign = this.$store.getters.getCampaignByKey(
                this.$route.params.campaignKey
            )
            return campaign.supportedRedemptionType != `RECEIPT`
        },
    },
})
</script>

<style scoped>
ul {
    padding-inline-start: 0;
}

.button {
    justify-content: flex-end;
    display: flex;
    margin-right: -30px;
}

#checkboxes {
    color: black;
}

#refresh-button {
    margin-top: 12px;
    margin-right: 18px;
}
</style>
