<template>
    <div class="card-box">
        <rb-text weight="bold" style="margin-bottom: 20px; display: block"
            >Search for canCode or uimUserId</rb-text
        >
        <rb-search
            v-on:searchChange="searchChanged"
            size="small"
            type="default"
            placeholder="uimUserId / canCode"
        ></rb-search>

        <ParticipationList />
    </div>
</template>

<script lang="ts">
import ParticipationList from './ParticipationList.vue'
import { defineComponent } from 'vue'
import { getFirstParamValue } from '@/commons/route.utils'

export default defineComponent({
    name: 'ParticipationSearch',
    components: {
        ParticipationList,
    },
    data() {
        return {
            campaignKey: '',
            timeout: undefined as NodeJS.Timeout | undefined,
            previousSearchTerm: '',
        }
    },
    mounted() {
        this.campaignKey = getFirstParamValue(this.$route.params.campaignKey)
    },
    methods: {
        triggerSearch: function (searchParam: string) {
            if (searchParam !== this.previousSearchTerm) {
                this.previousSearchTerm = searchParam
                this.$store.dispatch('searchForParticipation', {
                    campaignKey: this.campaignKey,
                    searchTerm: searchParam,
                })
            }
        },
        searchChanged: function (event: CustomEvent<string>) {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.triggerSearch(event.detail)
            }, 500)
        },
    },
})
</script>

<style scoped></style>
