<template>
    <rb-card :heading="stats!.promotionName + ' (' + stats!.promotionKey + ')'">
        <div slot="info">
            <div class="info-container">
                <rb-text>
                    Max. redemptions per user:
                    {{ stats!.maxRedemptionsPerUser }}
                    <span> &nbsp; | &nbsp; </span>
                    {{ stats!.validFrom }} &nbsp; --> &nbsp;
                    {{ stats!.validUntil }}
                </rb-text>
            </div>
        </div>
        <div slot="cta">
            <router-link :to="detailsForPromotion">
                <rb-button
                    type="primary"
                    size="small"
                    style="padding-right: 8px"
                >
                    Details
                </rb-button>
            </router-link>
            <router-link :to="updatePromotion">
                <rb-button
                    type="primary"
                    size="small"
                    style="padding-right: 8px"
                >
                    Update
                </rb-button>
            </router-link>
            <rb-button
                type="secondary"
                v-bind:disabled="deleteButtonDisabled"
                size="small"
                @click="deletePromotion"
            >
                Delete
            </rb-button>
        </div>
        <div slot="meta">
            <div class="rb-card-meta-content" id="meta-stats">
                Total:
                {{
                    stats!.redemptionCount + stats!.nonConfirmedRedemptionCount
                }}
                <br />
                <div v-if="unconfirmedVisible">
                    Unique: {{ stats!.redemptionCount }}
                </div>
            </div>
        </div>
    </rb-card>
</template>
<script lang="ts">
import { PromotionStatistics } from '@/models/promotionStatistics.model'
import { defineComponent } from 'vue'
import { getFirstParamValue } from '@/commons/route.utils'

export default defineComponent({
    props: {
        stats: Object as () => PromotionStatistics,
    },
    methods: {
        deletePromotion() {
            if (!this.deleteButtonDisabled) {
                let c = confirm('Really delete promotion?')
                if (c) {
                    let payload = {
                        promotionKey: this.stats!.promotionKey,
                        campaignKey: this.$route.params.campaignKey,
                    }
                    this.$store.dispatch('deletePromotion', payload)
                }
            }
        },
    },
    computed: {
        unconfirmedVisible() {
            const stats = this.stats as PromotionStatistics
            return stats.unconfirmedPossible
        },
        deleteButtonDisabled(): boolean {
            const stats = this.stats as PromotionStatistics
            return stats.redemptionCount != 0
        },
        updatePromotion() {
            const campaignKey = getFirstParamValue(
                this.$route.params.campaignKey
            )
            const promotionKey = this.stats!.promotionKey
            return `/promotion_update/${campaignKey}/${promotionKey}`
        },
        detailsForPromotion() {
            const campaignKey = getFirstParamValue(
                this.$route.params.campaignKey
            )
            const promotionKey = this.stats!.promotionKey
            return `/promotion_details/${campaignKey}/${promotionKey}`
        },
    },
})
</script>
<style lang="scss" scoped>
#meta-stats {
    text-align: left;
    color: dimgray;
}
</style>
