<template>
    <div class="content-box card-box">
        <span>Send Rewards</span>
        <div class="text-input">
            <rb-input
                label="Reward Id"
                required="true"
                v-on:inputChanged="rewardIdChanged"
                ref="rewardIdInput"
                :value="id"
            />
        </div>
        <div class="text-input">
            <rb-input
                label="Rank From"
                required="true"
                v-on:inputChanged="rewardFromChanged"
                ref="rewardFromInput"
                placeholder="Rank From"
                :value="from"
            />
            <rb-input
                label="Rank To"
                required="true"
                v-on:inputChanged="rewardToChanged"
                ref="rewardToInput"
                placeholder="Rank To"
                :value="to"
            />
        </div>
        <div class="text-input">
            <rb-button v-on:click="sendRewards">Send Rewards </rb-button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'LeaderboardRewardsComponent',
    data: function () {
        return {
            numberValidation: {
                regex: '^[1-9]{1}[0-9]*$',
                errorMessage: 'Invalid number',
            },
            id: undefined,
            from: undefined,
            to: undefined,
        }
    },
    props: ['campaignKey', 'promotionKey'],
    methods: {
        rewardIdChanged: function (event: CustomEvent) {
            this.id = event.detail == '' ? undefined : event.detail
        },
        rewardToChanged: function (event: CustomEvent) {
            this.to = event.detail == '' ? undefined : event.detail
        },
        rewardFromChanged: function (event: CustomEvent) {
            this.from = event.detail == '' ? undefined : event.detail
        },
        sendRewards() {
            var body = {
                campaignKey: this.campaignKey,
                promotionKey: this.promotionKey,
                skuId: this.id,
                rankFrom: this.from,
                rankTo: this.to,
            }
            this.$store.dispatch('triggerRewardCeremony', body)
        },
    },
})
</script>

<style scoped></style>
